.unqiue-step-three {
  .blue-label {
    color: #005ea2;
    font-weight: bold;
    text-transform: uppercase;
  }

  .blk-label {
    font-weight: bold;
  }

  .form-btn svg{
    vertical-align: middle;
    margin: 0px 4px 0px 0px;
  }

  .form-btn {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
