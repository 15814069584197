.afp-filter .usa-accordion__content {
  /* Hack to unset accordion item overflow*/
  overflow: unset;
}

.afp-filter .opts-container {
  /* this is similar to USWDS mobile-lg */
  min-width: 379px;
}

.unique-rate-filter-container .afp-filter > div > div.flex-row {
  margin: 20px 0px;
}

.unique-rate-filter-container #rateStatus + h2 button {
  padding-right: 0px;
}
