#opt-in {
  .bm_create_category_service_button {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0 2rem 1rem;
  }

  .modal-grid-container .grid-col-12,
  #optInSvcDesc {
    width: 100%;
    max-width: 100%;
  }

  .rate-calculator {
    background-color: #f7fafe;
    padding: 10px 30px 30px 30px;
    margin-top: 8px;
    border: 1px solid #bacfe9;
    border-radius: 5px;
    .usa-radio {
      background-color: inherit;
    }
  }
}
