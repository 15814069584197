#lease-rate-update {
  .action-btn {
    padding: 10px 0;
    text-align: left;
  }

  #lease-rate-update-form {
    margin-bottom: 48px;

    .grid-container {
      padding: 0;
      margin: 0;
      max-width: 100%;
    }

    .grid-container .grid-col-12,
    #description {
      width: 100%;
      max-width: 100%;
    }

    #monthly-rate,
    #mileage-rate {
      padding-left: 1.5rem;
    }

    #salvage-percent,
    #useful-life-in-month,
    #avg-age-at-sale,
    #opt-equip-markup,
    #high-cost-threshold-markup {
      text-align: right;
    }

    #salvage-percent {
      padding-right: 2rem;
    }

    #useful-life-in-month,
    #avg-age-at-sale {
      padding-right: 4.75rem;
    }

    .prefix-icon-percent,
    .prefix-icon-months {
      position: absolute;
      bottom: 0.375rem;
      right: 1.75rem;
      color: #71767a;
    }
  }

  .usa-date-picker__calendar__table {
    margin-bottom: 12px;
  }
  .usa-date-picker__calendar__date {
    padding: 6px;
    border: 3px solid transparent;
    &:not([disabled]) {
      &:hover {
        border: 3px solid #4591f7;
      }
    }
  }
}
