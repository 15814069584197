.row-status{
    display: flex;
    justify-content: space-between;
    
    border-bottom: 1px solid #a9aeb1;
}

.sub-wrapper{
    margin-right: 2opx;
}

.title {
    border-bottom: 1px solid #a9aeb1;
}