.flex-end-row {
  align-items: flex-end;
}

.bm_create_rate_button {
  display: flex;
  justify-content: flex-end;
  margin: -40px 0 16px 0;
}

.bm-form-row {
  margin-top: 1.5rem;

  .bm-form-row-input {
    display: flex;
    height: 100%;
    margin-top: 0;
    flex-direction: column;
    justify-content: space-between;
    label {
      margin-top: 0;
    }
  }
  .bm-form-row-button {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: flex-end;
  }
}

.bm-prefix-icon-dollar {
  position: absolute;
  bottom: 0.625rem;
  left: 1.25rem;
  font-size: 1.25rem;
  color: #71767a;
}
.bm-prefix-icon-dollar-error {
  left: 2.5rem;
}

.bm-rate-date-picker-error {
  input {
    border: 0.25rem solid #b50909;
  }
}

.bm-error-border-color {
  border-color: #b50909;
}

.color-red {
  color: #b50909;
}

.color-blue {
  color: #005ea2 !important;
}

.modal-grid-container {
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.bm-row-action > .dropdown {
  > .usa-button {
    padding: 0 0.5rem;
  }
  .dropdown-content {
    .dropdown-menu-item {
      button {
        margin: 0;
        padding: 1.125rem 1.5rem 1.125rem 0.75rem;
      }
    }
  }
}

.bm-banner-message-close {
  color: black;
  width: 24px;
  height: 24px;
}

.bm-subrow-grid-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #a9aeb1;
}
.bm-subrow-header {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
}
.bm-subrow-title {
  font-weight: bold;
}

.bm-section-header {
  font-size: 18px;
  color: #005ea2;
  font-weight: bold;
  text-transform: uppercase;
}

.afp-responsive-table tbody tr.table__sub-component_row {
  word-break: break-word;
}
