#vehicle-listing {
  .button-link {
    border: none;
    background-color: transparent;
    margin: 0 24px 0 8px;
    color: #005ea2;
    text-decoration: underline;
    cursor: pointer;
  }

  .selection-summary {
    margin: 16px 0;
    > span {
      font-weight: bold;
    }
  }

  .apply-rate-to-all {
    margin-bottom: 24px;
  }
  .apply-rate-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 8px;
    > Button {
      margin-top: 33px;
    }
  }
  .apply-rate-select {
    width: 200px;
  }
  .vin-search-box {
    width: 300px;
  }
  .rate-input-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 16px;
    label {
      font-weight: bold;
    }
  }
  .apply-rate-to-selected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 24px 0;
  }

  .rate-inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .rate-input {
    position: relative;
    .prefix-icon-dollar-global,
    .prefix-icon-dollar-table {
      position: absolute;
      font-size: 1.25em;
      color: #71767a;
    }
    .prefix-icon-dollar-global {
      top: 18px;
      left: 10px;
    }
    .prefix-icon-dollar-table {
      top: 9px;
      left: 4px;
    }
    input {
      text-indent: 24px;
    }
  }
}

.ura-vehicle-listing-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .vehicle-listing-box {
    min-width: 200px;
    flex: 1;
  }
  .vehicle-listing-spacing {
    flex: 1;
  }

  table {
    width: 100%;
    min-width: 800px;
    border-spacing: 0;
    th,
    td {
      padding: 0 1rem;
      text-align: left;
      vertical-align: middle;
    }
    th {
      height: 4rem;
      border-bottom: 2px solid #1b1b1b;
    }
    td {
      height: 3rem;
      border-bottom: 1px solid #ccc;
    }
    .pad-sort-icon {
      padding-left: 3rem;
    }
    .input-cell {
      vertical-align: top;
    }
    .rate-select-box {
      margin: 3px 0;
    }
    .bm-ura-checkbox {
      margin-top: -12px;
      .usa-checkbox__label {
        margin: 0;
      }
    }
  }
}

.ura-apply-rate-to-selected {
  .usa-icon {
    margin: 0 0.25rem -0.25rem 0;
  }
}

.bm-sort-icon {
  button,
  button:hover {
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
    text-decoration: none;
    span {
      font-weight: 700;
      color: #000;
      vertical-align: middle;
    }
  }
  .usa-icon {
    margin: 0 0.25rem 0 0;
  }
}
