#unique-rate-update {
  #unique-rate-update-form {
    margin-bottom: 48px;

    .grid-container {
      padding: 0;
      margin: 0;
      max-width: 100%;
    }

    .grid-container .grid-col-12,
    #description {
      width: 100%;
      max-width: 100%;
    }

    #threshold-amount {
      padding-left: 22px;
    }

    #monthly-rate-factor,
    #mileage-rate-factor {
      text-align: right;
    }
  }

  .usa-date-picker__calendar__table {
    margin-bottom: 12px;
  }
  .usa-date-picker__calendar__date {
    padding: 6px;
    border: 3px solid transparent;
    &:not([disabled]) {
      &:hover {
        border: 3px solid #4591f7;
      }
    }
  }
}
