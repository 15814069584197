@import './global-bm.scss';

#main-content a {
  color: #005ea2 !important;
}

#main-content .white-anchor-text {
  color: white !important;
  white-space: nowrap;
}

#main-content .usa-button-group__item > a {
  color: white !important;
}

.loading_backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
}
