#sin-fuel-type-table {
  .usa-alert--warning {
    padding: 8px 24px;
    margin: 24px;
  }

  .remove-selected-btn {
    margin-left: 16px;
  }
}

#sin-fuel-type-table table {
  .usa-input {
    padding: 0;
    margin: 0;
  }

  input[type='text'] {
    margin-top: 0;
  }

  // SIN
  th:nth-child(1),
  td:nth-child(1) {
    width: 40%;
    padding: 1rem 0 1rem 2rem;
  }
  // fuel type
  th:nth-child(2),
  td:nth-child(2) {
    width: 40%;
    padding: 1rem 0;
  }
  // actions
  th:nth-child(3),
  td:nth-child(3) {
    width: 20%;
    padding: 1rem 2rem 1rem 0;
  }

  // move container in expanded row
  .sin-fuel-move-container {
    background-color: #eff2f4;
    border: 1px solid #b8b8b8;
    margin: 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      > p {
        font-weight: bold;
      }
    }
  }

  .row-actions {
    margin-bottom: 0;
  }

  .add-assoc-btn {
    .usa-button {
      margin: 0;
    }
  }

  .move-cancel-btn {
    border: none;
    background-color: inherit;
    cursor: pointer;
  }
}
