.unique-rates-app-heading {
  display: flex;

  .unique-rates-app-welcome-msg {
    flex: 6;
  }

  .unique-rates-app-save-draft > button {
    position: relative;
    top: -12px;
  }
}

.unique-rates-app-btns {
  margin: 128px 0;
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
}

.unique-rates-app-section-header {
  font-size: 18px;
  color: #005ea2;
  font-weight: bold;
  text-transform: uppercase;
}

.action-button-box-left {
  display: flex;
  justify-content: flex-start;
  button {
    margin: 0 20px 0 0;
  }
}
.action-button-box-right {
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0 0 20px;
  }
}

.loading-spinner {
  position: relative;
  height: 36px;
  margin-top: -36px;
}

.review-note {
  background-color: #f6f6f6;
  padding: 10px;
}

.review-comment-head {
  color: #005ea2;
}
