/* Dropdown Button */
.dropdown-button {
  /* add button style if necessary */
  box-shadow: none;
}

/* Dropdown button on hover & focus */
.dropdown-button:hover,
.dropdown-button:focus {
  box-shadow: none;
}

.dropdown-menu-button {
  color: #1b1b1b;
  text-decoration: none;
  padding-left: 10px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  padding: 10px 0px;
  min-width: 240px;
  background: #ffffff;
  border: 1px solid #71767a;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.128223);
}

hr.afp-divider {
  border: 1px solid #dcdee0;
}

.dropdown-content button {
  display: flex;
  margin-left: 5px;
  box-shadow: none;
  font-weight: 100;
}

.dropdown-content button:hover {
  box-shadow: none;
}

.dropdown-content-icon {
  margin-right: 10px;
  vertical-align: middle;
  color: #71767a;
}

.show {
  display: block;
}

.table-header__type-of-unique-rate button {
  display: flex;
  align-items: baseline;
}

@media screen and (max-width: 479px) {
  .dropdown-content {
    left: 0;
  }
  .text-right {
    text-align: left;
  }
}
@media screen and (min-width: 40em) {
  .afv-funding-table-container {
    table {
      overflow-y: auto;
      max-height: 600px;
      display: block;
      border-collapse: separate;

      thead {
        tr:first-child {
          position: sticky;
          top: 0;
          z-index: 999;
          th {
            padding: 1.25em 1em;
            background-color: white;
            border-bottom: 2px solid #1b1b1b;
          }
        }
      }

      tbody {
        tr {
          td,
          th {
            padding: 1em 1em;
            border-top: 0;
          }
          &:last-child {
            position: sticky;
            bottom: -1px;
            td,
            th {
              font-weight: bold;
              background: #f6f6f6;
              border-bottom: none;
              border-top: 2px solid #1b1b1b;
            }
          }
          &:nth-last-child(2) {
            td,
            th {
              border-bottom: none;
            }
          }
        }
      }

      .action-btn:hover {
        background-color: #ccc;
      }
      .action-btn button {
        padding: 10px 10px;
      }
      .action-btn hr {
        margin: 0px !important;
      }
    }
  }
  .afv-funding-table-viewonly-container {
    overflow-y: auto;
    max-height: 800px;
    table {
      border-collapse: separate;
      thead {
        tr:first-child {
          position: sticky;
          top: 0;
          z-index: 999;
          th {
            padding: 1.25em 1em;
            background-color: white;
            border-bottom: 2px solid #1b1b1b;
          }
        }
      }
      tbody {
        tr {
          td,
          th {
            padding: 1.5em 1em;
            border-top: 0;
          }
        }
      }
    }
  }
}

.afv-funding-table-container .table-nodata-container {
  background: #f6f6f6;
  padding: 120px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1024px) and (max-width: 1250px) {
  [data-testid='reset-filter'] {
    font-size: 11px;
  }
}


table.usa-table {
  display: table !important;
  width: 100%;
  position: relative;
  z-index: 0;
}

.afv-funding-filter-container {
  margin-top: 2.3rem !important;
}