.progress-import-container {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1000;
}

/* Transparent Overlay */
.process-import_overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}

.process-import_progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  background: #fff;
  padding: 2rem;
}

.process-import_progress progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
  min-width: 12rem;
  width: 35%;
  height: 1.25rem;
}
