/*Purchase rate page Styles*/

.textStyle {
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
}

#rates-table .afp-responsive-table > thead th {
  padding-top: 10px;
}

#rates-table .afp-responsive-table > tbody th,
#rates-table .afp-responsive-table > tbody td {
  vertical-align: top;
}

#rates-table > .usa-table > thead > th:nth-child(1),
#rates-table > .usa-table > tbody > td:nth-child(1) {
  display: none;
}

#rates-table > .usa-table > thead > th:nth-child(4) {
  width: 15%;
}

#rates-table > .usa-table > thead > th:nth-child(2) {
  width: 15%;
}

#rates-table > .usa-table > thead > th:nth-child(3) {
  width: 9%;
}

#rates-table > .usa-table > thead > th:nth-child(6) {
  width: 8.8%;
}

#rates-table > .usa-table > thead > th:nth-child(7),
#rates-table > .usa-table > thead > th:nth-child(8),
#rates-table > .usa-table > thead > th:nth-child(9) {
  width: 10%;
}

#rates-table > .usa-table > thead > th:nth-child(5) {
  width: 8%;
}

.usa-input--inline {
  background: white;
}

#modal-rs {
  padding: 0px 5px;
}

.rates-table .usa-table td {
  border-color: #a9aeb1;
}

/* Styles for Edit Modal Starts here*/

.purchaseModal {
  padding: inherit;
}

.purchaseModal .modal {
  padding: 0px;
  max-width: 800px;
  max-height: 100vh;
}

.purchaseModal .modal .title,
.purchaseModal .modal .content {
  padding: 10px 25px 10px;
}

.purchaseModal .modal .modalClose {
  margin: 10px 25px 10px;
}

.purchaseModal .actions {
  padding: 25px 40px;
}

.purchaseModal .modal > .title {
  margin-top: 60px;
  margin-left: 5px;
}

.purchaseModal legend {
  display: block;
  padding: 0px 10px;
  border-radius: 2px;
  background-color: white;
}

.purchaseModal .chooseRateFee .usa-radio__label {
  font-size: 14px;
}

.editButtonStyle {
  padding: 15px;
  position: absolute;
  margin-top: 20px;
}

.editModalMsg {
  margin-top: -5px;
}

label.required:after {
  content: ' * ';
  color: #ab200b;
}

.form-ra .usa-label {
  font-weight: bold;
}

.rateApplicationTitle {
  margin-top: 35px;
}

.pr-modal-alert {
  width: 150vw;
}

.pr-modal-alert .content {
  padding: 20px 0px 40px;
}

.purchaseTypeStyle {
  font-weight: bold;
  column-width: 140px;
}

/* Styles for Edit Modal Ends here*/

/*Styles for Flat Dollar and Fixed Percent inside Edit Modal Starts here*/

.fixedPercentStyle {
  font-weight: bold;
}

.flatDollarStyle {
  font-weight: bold;
}

.fixedpercentfield {
  padding: 2px 0px;
}

.fixed-flatStyle {
  padding: 2px 0px;
}

.fixedFlatStyle {
  width: 720px;
  max-width: 100%;
  background: #f7fafe;
  border: 1px solid #48a3ff;
  padding: 0.5rem 1rem 1rem;
  border-radius: 5px;
  box-sizing: border-box;
  border-color: #bacfe9;
}

.form-dollar,
.form-percent {
  display: inline;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #565c65;
}

.textFixedStyle {
  width: 230px;
  margin-left: 15px;
}

.field-ra {
  max-width: 100%;
}

.deletePendingStyle {
  background-color: darkred;
  float: right;
  margin-top: -15px;
}

.deleteStyle {
  background-color: darkred;
}

.confirmDelete {
  background-color: darkred;
}

.delete-modal #modal-rs {
  width: 100%;
  margin: 24px 0;
}

.purchaseModal .confirm-modal {
  height: auto !important;
  overflow: hidden;
}

/*Styles for Flat Dollar and Fixed Percent inside Edit Modal Ends here*/

.iconStyle {
  color: gray;
  margin-top: 2px;
}

.effectivestartdateerrorstyle {
  color: red;
}

/*Success Modal Styles*/

.success-modalstyle {
  margin-right: 693px;
}

.successStyleModal .success-deleteStyle {
  margin-bottom: 40px;
  padding: 10px;
}

/* Dropdown Button */
.dropdown-button {
  /* add button style if necessary */
  box-shadow: none;
}

/* Dropdown button on hover & focus */
.dropdown-button:hover,
.dropdown-button:focus {
  box-shadow: none;
}

.dropdown-menu-button {
  color: #1b1b1b;
  text-decoration: none;
  padding-left: 10px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  padding: 10px 0px;
  min-width: 240px;
  background: #ffffff;
  border: 1px solid #71767a;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.128223);
}

hr.afp-divider {
  border: 1px solid #dcdee0;
}

.dropdown-content button {
  margin-left: 5px;
  box-shadow: none;
  font-weight: 100;
  display: flex;
}

.dropdown-content button:hover {
  box-shadow: none;
}

.dropdown-content-icon {
  margin-right: 10px;
  vertical-align: middle;
  color: #71767a;
}

.show {
  display: block;
}

.purchaseModal .modal .modalClose:after {
  clear: both;
  content: ' ';
}

.purchaseModal .modalContainer .modal {
  padding: 0;
  overflow-y: auto;
  height: 100%;
  max-width: 800px;
}

.purchaseModal .modalContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  margin: 0px auto;
  height: 100%;
  overflow-y: auto;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

.rateApp-container {
  position: relative;
}

#rates-table .table-nodata-container {
  background: #f6f6f6;
  padding: 120px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#rates-table .noAction {
  color: #1b1b1b;
  font-weight: lighter;
}

#purchase-type-desc {
  max-width: 100%;
}
