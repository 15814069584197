#afv-funding-surcharge-history,
#afv-concurrence-history-table {
  .react-tabs__tab-list {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  #surcharge-history-table {
    .afp-responsive-table.usa-table tbody > tr > td:nth-child(7) {
      text-align: center;
    }

    .afp-comments {
      li {
        overflow-wrap: anywhere;
      }
    }
  }

  .afp-details-table {
    background: transparent;
  }

  .afp-details-table .usa-prose > table th,
  .usa-table th,
  .usa-prose > table td,
  .usa-table td {
    border: 0;
  }

  .afp-details-table th,
  .afp-details-table td {
    border-bottom: 1px solid #a9aeb1;
    background: transparent;
  }

  .afp-details-table th {
    padding-left: 0;
    font-weight: bold;
  }

  .afp-details-table td {
    padding-right: 0;
  }

  #surcharge-details {
    .grid-col-6:first-child {
      padding-left: 0;
      padding-right: 3rem;
    }

    .grid-col-6:last-child {
      padding-left: 3rem;
      padding-right: 0;
    }
  }
}
