.minusBalance {
  color: #e41d3d;
}

#afv-surcharge-funding {
  .prefix-icon-dollar {
    position: absolute;
    bottom: 10px;
    left: 6px;
    font-size: 1.25em;
    color: #71767a;
  }

  .dollar-input {
    position: relative;
  }

  .dollar-input input {
    padding-left: 22px;
  }

  .fund-save-btn {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}

.surcharge-calculator {
  background-color: #f7fafe;
  padding: 10px 30px 30px 30px;
  margin-top: 35px;
  border: 1px solid #bacfe9;
  border-radius: 5px;
  .usa-radio {
    background-color: inherit;
  }
}

.surcharge-calculator-heading {
  color: #005ea2 !important;
}

.afv-concurrence-new-surcharge {
  font-size: 34px;
  color: #0053a2;
}

.afv-surcharge-thankyou {
  min-height: 500px;
}

.relax-padding span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.afv-surcharge {
  width: 9rem;
}