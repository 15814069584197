.purchase-rate-filter-container .StatusFilter button {
  text-decoration: none;
  font-size: 16px;
  width: 207px;
}

.purchase-rate-filter-container .StatusFilter {
  margin-top: -25px;
}

.purchase-rate-filter-container .StatusFilter label {
  font-weight: 400;
  font-size: 16px;
}

.usa-accordion__content.StatusFilter > div {
  padding-top: 20px;
}

.purchaseTypeFilter button {
  text-decoration: none;
  font-size: 16px;
  width: 207px;
}

.purchase-rate-filter-container .text-bold {
  margin-top: 30px;
  font-weight: 900;
}

.purchase-rate-filter-container button {
  margin-top: 30px;
}

