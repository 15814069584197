#set-surcharge-form,
#update-surcharge-form {
  .prefix-icon-dollar {
    position: absolute;
    bottom: 10px;
    left: 20px;
    font-size: 1.25em;
    color: #71767a;
  }

  .dollar-error {
    top: 64px;
  }

  .dollar-error-left {
    left: 44px !important;
  }

  .dollar-error-dates {
    bottom: 42px !important;
  }

  input[name='afvSurchargeAmount'] {
    padding-left: 22px;
  }

  textarea[name='comment'] {
    max-width: 100%;
  }
}

#afv-update-surcharge-modal {
  div[data-testid='modal'] {
    padding: 0;
    overflow: auto;
    height: auto;
    min-width: 1024px;
    max-width: 1024px;
    max-height: 100%;
  }
}
