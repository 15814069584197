label.usa-label {
  font-weight: bold !important;
}

.mileage-rate {
  display: inline-flex;

  div.usa-radio:nth-child(1) {
    flex: 1;
    margin-right: 32px;
  }

  div.usa-radio:nth-child(2) {
    flex: 1;
  }
}

.monthly-rate-options .usa-label {
  max-width: max-content;
}

.justification textarea,
.usa-file-input {
  max-width: 70em;
}

.ura-upload-moa {
  .usa-file-input {
    min-width: 30rem;
    max-width: 48rem;
  }
}
