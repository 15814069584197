.minusBalance {
  color: #e41d3d;
}

#acquisition-funding {
  // .dollar-input {
  //   position: relative;
  // }

  // .dollar-input input {
  //   padding-left: 22px;
  // }

  #balance-tab {
    tbody {
      tr:last-child {
        bottom: 0;
        td,
        th {
          background: #f6f6f6;
          border-bottom: 2px solid #000000;
          border-top: 2px solid #1b1b1b;
        }
        .first-cell {
          font-weight: bolder;
        }
      }

      td.cell-right-padding {
        padding-right: 0.6rem;
      }
    }

    .cell-right div.table-header__wrapper {
      justify-content: right;
    }
  }
}

.fund-btn {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.acquisition-calculator {
  background-color: #f7fafe;
  padding: 10px 30px 30px 30px;
  margin-top: 35px;
  border: 1px solid #bacfe9;
  border-radius: 5px;
}

.acquisition-calculator-heading {
  color: #005ea2 !important;
}

.currency-error {
  top: 10px !important;
  left: 20px !important;
}

.usa-tooltip__body {
  position: absolute;
  max-width: 350px;
  min-width: 150px;
  min-height: 120px;
  height: 180px;
  margin-top: 23px;
  word-wrap: break-word;
  white-space: break-spaces !important;
  right: -1rem;
  bottom: -180px;
}

.usa-tooltip__body:after, .usa-tooltip__body--top:after {
  border: none !important;
}