.afp-filter .usa-accordion__content {
  /* Hack to unset accordion item overflow*/
  overflow: unset;
}

.afp-filter .opts-container {
  /* this is similar to USWDS mobile-lg */
  min-width: 379px;
}

.unique-rate-filter-app-container .afp-filter > div > div.flex-row {
  margin: 20px 0px;
}

.unique-rate-filter-app-container #customerNumber + h2 button {
  padding-right: 0px;

}

.unique-rate-filter-app-container .usa-accordion__button{
  font-size: 0.85rem; 
}

.unique-rate-filter-app-container .StatusFilter .usa-checkbox__label{
  font-size: 0.9rem;
}

