.review-request-info {
  max-width: 100%;
  width: 100%;

  p {
    max-width: 100%;
  }
}

div.title-status {
  display: flex;

  h1 {
    margin-right: 2rem;
  }

  div {
    span {
      position: relative;
      top: 38px;

      span {
        top: 0;
      }
    }
  }
}

#unique-rate-app-review {
  textarea[name='comments'] {
    max-width: 100%;
  }

  .info-box {
    display: flex;
    flex-direction: column;
  }

  h3.info-title {
    color: #005ea2;
    font-weight: 800;
  }

  div.info-details {
    background: #f6f6f6;
    padding: 1.5rem;
    flex-grow: 1;
    word-break: break-word;

    .afp-details-table {
      background: #f6f6f6;
    }
  }

  div.info-details.fleet-card-details {
    .afp-details-table {
      tr {
        border: none;
      }

      th {
        vertical-align: top;
      }

      td {
        text-align: right;
        white-space: break-spaces;
      }
    }
  }

  div.info-details.customer-info {
    .afp-details-table {
      tbody {
        tr:last-child {
          td {
            text-decoration: underline;
            color: #005ea2;
          }
        }
      }
    }
  }

  div.vehicle-details-table {
    table {
      max-width: 100%;
      width: 100%;
    }
  }

  .review-action-btns {
    display: flex;
    justify-content: space-between;
    width: 150px;
    margin-left: 4px;
  }
}
