/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background: #ffffff;
  border: 1px solid #71767a;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.128223);
  z-index: 1;
  right: 0;
  text-align: left;
  min-width: max-content;
  margin-left: 10px;
}

.dropdown-content .dropdown-menu-item:hover {
  background-color: #f0f0f0;
}

.dropdown-content button {
  width: 100%;
}

.dropdown-content button:hover {
  text-decoration: none;
  color: #000;
  svg {
    color: #005ea2;
  }
}

.show {
  display: block;
}

.top-action-button {
  min-width: 240px;
  margin: 0px;
}

.create-new-req {
  span {
    padding-right: 10px;
  }
  .iconStyle {
    color: #005ea2;
    font-weight: bolder;
    width: 26px;
  }
}
.dropdown-menu-item {
  button,
  .iconStyle {
    color: #005ea2;
    font-weight: bold;
  }
}
