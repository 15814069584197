/* Dropdown Button */
.dropdown-button {
  /* add button style if necessary */
  box-shadow: none;
}

/* Dropdown button on hover & focus */
.dropdown-button:hover,
.dropdown-button:focus {
  box-shadow: none;
}

.dropdown-menu-button {
  color: #1b1b1b;
  text-decoration: none;
  padding-left: 10px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  padding: 10px 0px;
  min-width: 240px;
  background: #ffffff;
  border: 1px solid #71767a;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.128223);
}

hr.afp-divider {
  border: 1px solid #dcdee0;
}

.dropdown-content button {
  display: flex;
  margin-left: 5px;
  box-shadow: none;
  font-weight: 100;
}

.dropdown-content button:hover {
  box-shadow: none;
}

.dropdown-content-icon {
  margin-right: 10px;
  vertical-align: middle;
  color: #71767a;
}

.show {
  display: block;
}

.table-header__type-of-unique-rate button {
  display: flex;
  align-items: baseline;
}

@media screen and (max-width: 479px) {
  .dropdown-content {
    left: 0;
  }
  .text-right {
    text-align: left;
  }
}

.unique-rate-app-table-container > table > thead tr > th {
  border-width: 2px;
}

.unique-rate-app-table-container > table > thead > tr > th:nth-child(1) {
  padding: 10px;
  width: 0.8%;
}

.unique-rate-app-table-container .afp-responsive-table tbody tr td {
  overflow-wrap: break-word;
  padding: 1.5em 1em;
}

.unique-rate-app-table-container .afp-responsive-table__expanded-row td:nth-child(2) {
  font-weight: bold;
}

.unique-rate-app-table-container .table-header__lease-rate-code {
  width: 14%;
}

.unique-rate-app-table-container .afp-responsive-table-header__sort-button {
  padding-bottom: 3px;
  align-items: flex-end;
}

.unique-rate-app-table-container .table-header__status {
  width: 12%;
}

.unique-rate-app-table-container .table-header__no-accessor-fuel-type {
  width: 14%;
}

.unique-rate-app-table-container .table-header__start-date {
  width: 14%;
}

.unique-rate-app-table-container .afp-responsive-table > thead th {
  vertical-align: bottom;
}

.unique-rate-app-table-container .usa-icon--size-4 {
  margin-right: -15px;
}

.statusStyle {
  margin-top: 15px;
}

.unique-rate-app-table-container .lineData {
  line-height: 28px;
}

.unique-rate-app-table-container .usa-prose .margin-bottom-4 {
  margin-bottom: 5rem;
}

.textStyle {
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
}

.leaseintro.usa-prose > p {
  font-weight: 500;
  font-size: 16px;
  line-height: 25.92px;
  margin-top: -15px;
  margin-bottom: 70px;
}

.unique-rate-app-table-container .table-header__status .table-header__name,
.unique-rate-app-table-container .table-header__start-date .table-header__name {
  margin-top: 15px !important;
}

.unique-rate-app-table-container .action-btn:hover {
  background-color: #ccc;
}

.unique-rate-app-table-container .action-btn button {
  padding: 10px 10px;
}

.unique-rate-app-table-container .action-btn hr {
  margin: 0px !important;
}

.unique-rate-app-table-container .table-nodata-container {
  background: #f6f6f6;
  padding: 120px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1024px) and (max-width: 1250px) {
  [data-testid='reset-filter'] {
    font-size: 11px;
  }
}
